const body = document.querySelector("body");
const boutonmention = document.querySelector(".mentions");
let isPagementionOpen = false;
let pagemention;

const closemention = () => {
    pagemention.classList.remove("open");
}

const createmention = () => {
    pagemention = document.createElement("div");
    pagemention.classList.add("mention-container");
    console.log('add ok!');
    pagemention.innerHTML = `
    <section class="mention-content">
        <h3>Mentions légales</h3>
        <article>
            <h4>Etang-pêche-valdivienne.fr est édité par : </h4>
<p>Site réalisé par Mathieu SIAUDEAU. Étudiants en développement d'application web fullstack. En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site https://etang-pêche-valdivienne.fr/ l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi, Personne moral et physique resterons anonyme
Créateur : Mathieu SIAUDEAU Responsable publication :  mathieusiaudeau@gmail.com Le responsable publication est une personne physique ou une personne morale.</p>

<h4>Le site brasseriedifre.fr est hébergé par : </h4>
<p>o2switch,

SARL au capital de 100000€ dont le siège social est établi 222-224 Boulevard Gustave Flaubert 63000 Clermont-Ferrand immatriculée au Registre du Commerce et des Sociétés sous le numéro 510 909 80700024 - RCS Clermont Ferrand</p>

<h4>Déclaration de confidentialité</h4>
<p>Etang-pêche-valdivienne.fr prête une attention particulière à vos données personnelles, et s’engage de par ses conditions d’utilisation à ne transmettre, vendre ou céder à un tiers aucune information personnelle vous concernant. Veuillez également consulter notre Politique concernant la gestion des cookies

Conformément à la loi « Informatique et Libertés » N° 78-17 du 6 Janvier 1978, vous bénéficiez d’un droit d’accès, de rectification et d’opposition. Pour l’exercer, merci de bien vouloir nous contacter par l’intermédiaire du formulaire de contact.</p>

<h4> POLITIQUE DE GESTION DES COOKIES </h4>
<p>Cookies
Utilisé uniquement dans le but de confirmer ou non l'envoi du mail, sur le formulaire de contact. </p>

<h4>Liste des cookies générés par ce site</h4>
<p>PHPSESSID</br>
Ce cookie technique, indispensable, sert à optimiser l'expérience des visiteurs.</p>
        </article>
        <button>FERMER</button>
    </section>
`;
    body.append(pagemention);
   // pagemention.addEventListener("click" ,event => {
   //     event.stopPropagation();
   //     console.log("clique OK!");
   // })
};

const Openmention = () => {
    if(!pagemention) {
        createmention();
        console.log('creationmention');
    }
    pagemention.classList.add("open");
};

const togglemention = event => {
    if(isPagementionOpen) {
        closemention();
        console.log('close');
    }  else {
        Openmention();
        console.log('open');
    }

    isPagementionOpen = !isPagementionOpen;
};

boutonmention.addEventListener("click", event => {
    event.stopPropagation();
    togglemention();
    console.log("clique OK!");
});

window.addEventListener("click",() => {
    if(isPagementionOpen) {
        togglemention();
        console.log('mention fermé !');
    }
});

